import { createStyles, px, rem } from "@mantine/core";

export const useStyles = createStyles((theme)=> ({
    container:{
        [theme.fn.smallerThan("xs")]:{
            // display:"none"
        }
    },
    bataanLogo:{
        [theme.fn.smallerThan("xs")]:{
            display:"none"
        }
    }
}))