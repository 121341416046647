import React, { useEffect, useState, useRef } from "react";
import { Carousel } from "@mantine/carousel";
import Header from "../../component/Header/Header";
import Autoplay from "embla-carousel-autoplay";
import Telemetry from "../../component/Telemetry/Telemetry";
import { plantData } from "../../types/plantsData";
import { getPlants } from "../../service/plants";
import Footer from "../../component/Footer/Footer";
import axios from "axios";

const Monitoring = () => {
  const [plants, setPlants] = useState<plantData[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const autoplay = useRef(Autoplay({ delay: 60000 }));

  const onSlideChange = (index: number) => {
    setActiveIndex(index);
    autoplay.current.reset();
  };

  const totalKWHGenerated = plants.reduce((total, plant) => {
    return total + (plant.statistics?.kwh || 0);
  }, 0);
  
  useEffect(() => {
    const fetchData = async () => {
      const response = await getPlants();
      if (response !== undefined) {
        setPlants(response);
      }
    };
    fetchData();
  }, []);

  

  return (
    <div>
      <div>
        <Header plant={plants.length} totalKWHGenerated={totalKWHGenerated}/>
      </div>
      <div>
        <Carousel
          slideSize={"100%"}
          align="start"
          slidesToScroll={1}
          plugins={[autoplay.current]}
          controlSize={50}
          loop={true}
          onSlideChange={(index) => {
            onSlideChange(index);
          }}
        >
          {plants?.length
            ? plants.map((plant, index) => (
                <Carousel.Slide key={index}>
                  {index === activeIndex && (
                    <Telemetry plant={plant} key={plant.id} />
                  )}
                </Carousel.Slide>
              ))
            : null}
        </Carousel>
      </div>
      <Footer />
    </div>
  );
};

export default Monitoring;
