import React, { useEffect, useState } from "react";
import { Card, Text, Image, Grid, Group } from "@mantine/core";
import { plantData } from "../../types/plantsData";
import { getWeather } from "../../service/weather";
import { CurrentWeatherResponse } from "../../types/weather";
import {weatherIcons} from '../../assets/icons';
import { useStyles } from "./styles";

const Weather = ({ plant }: {plant: plantData}) => {
  const { classes } = useStyles();
  const [weatherData, setWeatherData] = useState<CurrentWeatherResponse>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getWeather(plant.longitude, plant.latitude);
        setWeatherData(response);
      } catch (error) {
        console.error("Error fetching weather:", error);
      }
    };
    fetchData();
  }, [plant.longitude, plant.latitude]);

  const getImageSrc = () => {
    if (weatherData) {
      const icon = weatherData.current.weather[0].icon;
      const iconPath = weatherIcons[icon];
      return iconPath || null;
    }
    return null;
  };

  return ( 
    <Card className={classes.card}>
      <Group position="center">
        <Image src={getImageSrc()} alt="Weather icon" width={80} pt={13} />
      </Group>  
      <Text style={{fontWeight: "bold"}} fz={24}>
        {weatherData?.current?.temp}°C
      </Text>
      <Group position="center" pb={3}>
        <Text>{weatherData?.current?.weather[0]?.description}</Text>
      </Group>
      <Grid>
        <Grid.Col span={4}>
          <Text c="dimmed" ta="center" fz="sm">
            Feel's like
          </Text>
          <Text c="dimmed" ta="center" fz="sm">
            {weatherData?.current?.feels_like}°C
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text c="dimmed" ta="center" fz="sm">
            humidity
          </Text>
          <Text c="dimmed" ta="center" fz="sm">
            {weatherData?.current?.humidity} %
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text c="dimmed" ta="center" fz="sm">
            wind speed
          </Text>
          <Text c="dimmed" ta="center" fz="sm">
            {weatherData?.current?.wind_speed} k/h
          </Text>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default Weather;
