import { createStyles, rem } from "@mantine/core";


export const ICON_SIZE = rem(100);
export const useStyles = createStyles((theme) => ({
    card: {
      position: "relative",
      overflow: 'visible',
      padding: theme.spacing.sm,
      height: "100%",
      border:`1px solid #dee2e6`,
      borderTop:"6px solid #34a853",
      borderRadius: theme.radius.md,

    },
  
    imageGroup: {
      paddingBottom: theme.spacing.md
    },
  
    title: {
      lineHeight: 1,
    },

    onlineIndicator: {
      position: "absolute",
      right: 0,
      bottom: 0,
      width: 27,
      height: 27,
      backgroundColor: "#4caf50",
      borderRadius: "50%",
      border: "2px solid #fff",
    },
  }));
  