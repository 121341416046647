import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    card: {
        textAlign: 'center',
        height: '100%',
        border:`1px solid #dee2e6`,
        borderTop:"6px solid #34a853",
        borderRadius: theme.radius.md,

    },
}))