import axios from "axios"
import {CurrentWeatherResponse} from "../types/weather"

export const getWeather = async (longitude: number, latitude: number): Promise<CurrentWeatherResponse> => {
    try {
        const weatherApiKey="3f3dc4a3373643169569c108556c0953"
        const baseUrl = `https://api.openweathermap.org/data/2.5/onecall?exclude=minutely,hourly,daily&units=metric&lat=${latitude}&lon=${longitude}&appid=${weatherApiKey}`;
        const weather = await axios.get(baseUrl);
        return weather.data 
    } catch (error) {
        console.error(error);
        throw error; 
    }
};