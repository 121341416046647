import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import {getFunctions }from "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyDtgEURT3ZLKHm5-kkqVrH3_a9k1x4amg0",
  authDomain: "solar-ems.firebaseapp.com",
  projectId: "solar-ems",
  storageBucket: "solar-ems.appspot.com",
  messagingSenderId: "967953557225",
  appId: "1:967953557225:web:be19536dafed7bd7827278",
  measurementId: "G-84MPWX0DNY"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore();
export const func = getFunctions(app, "asia-east1");


