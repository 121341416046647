import React, { useEffect, useState } from "react";
import Load from "../Load/Load";
import Profile from "../Profile/Profile";
import { Container, Grid } from "@mantine/core";
import { useStyles } from "./styles";
import { plantData } from "../../types/plantsData";
import Paho from "paho-mqtt";
import MQTT from "paho-mqtt";
import Weather from "../Weather/Weather";
import Chart from "../Chart/Chart";
import { getData, getLiveSolarEdge, getMqttServer } from "../../service/plants";
import { log } from "console";

type MqttServer = {
  hostname:string;
  port:number;
}

const Telemetry = ({ plant }: { plant: plantData }) => {
  const { classes } = useStyles();
  const [telemetryData, setTelemetryData] = useState<any>();
  const [mqttServer, setMqttServer] = useState<MqttServer>();
  const [data, setData] = useState<any>();
  const [client, setClient] = useState<any>(null);

  const telemetryDate = telemetryData?.ts;
  
  const onMessage = async (message: MQTT.Message) => {
    if (message.destinationName === `/telemetry/${plant.id}`) {
      const JSONMessage = JSON.parse(message.payloadString);
      setTelemetryData(JSONMessage);
      try {
        const responseData: any = await getData(JSONMessage);
        setData(responseData);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log("message destination is unexpected");
      console.log(message.destinationName);
    }
  };

  useEffect(() => {
    if (plant.id === "3237770") {
      const fetchLive = async () => {
        const response: any = await getLiveSolarEdge("live","3237770");
        console.log(response);
        setTelemetryData({ts: response?.lastUpdateTime});
        const solarProduce = response.currentPower.power / 1000;
        setData({solarProduce})
      };
      fetchLive();
      return;
    }
    // const mqttClient = new Paho.Client(
    //   "solarems-yhwkpo.a02.usw2.aws.hivemq.cloud",
    //   Number(8884),
    //   "client-id-dashboard"
    // );
 

    if(!mqttServer){
      const fetchServer = async()=>{
        const response: any = await getMqttServer();
        setMqttServer(response);
      }
      fetchServer();

    } else {
      const mqttClient = new Paho.Client(
        mqttServer.hostname,
        mqttServer.port,
        "client-id-dashboard"
      );
      mqttClient.connect({
        // userName: "solarems",
        // password: "GoTaneko888!",
        onSuccess: () => {
          setClient(mqttClient);
        },
        useSSL: true,
      });
      
      return () => {
        if (mqttClient.isConnected()) {
          mqttClient.disconnect();
        }
      };
    }

  }, [mqttServer]);

  useEffect(() => {
    if (client) {
      const payload = { command: "publish", payload: true };
      const message = new Paho.Message(JSON.stringify(payload));
      message.destinationName = `/command/${plant.id}`;
      client.send(message);
      client.subscribe(`/telemetry/${plant.id}`);
      client.onMessageArrived = (message: MQTT.Message) => {
        onMessage(message);
      };
    }
  }, [client, plant.id]);  
  const isSmallScreen = window.innerWidth <= 768;

  return (
    <Container my="md" className={classes.wrapper} size="95%">
      <Grid>
        {isSmallScreen ? (
          <>
            <Grid.Col xs={4}>
              <Profile plant={plant} time={telemetryDate} />
            </Grid.Col>
            <Grid.Col xs={6}>
              <Chart data={data} plantId={plant.id} />
            </Grid.Col>
            <Grid.Col xs={3}>
              <Load data={data} />
            </Grid.Col>
            <Grid.Col xs={4}>
              <Weather plant={plant} />
            </Grid.Col>
          </>
        ) : (
          <>
            <Grid.Col xs={12} md={6}>
              <Grid.Col>
                <Profile plant={plant} time={telemetryDate} />
              </Grid.Col>
              <Grid.Col>
                <Load data={data} />
              </Grid.Col>
              <Grid.Col>
                <Weather plant={plant} />
              </Grid.Col>
            </Grid.Col>

            <Grid.Col xs={12} md={6}>
              <Grid.Col>
                <Chart data={data} plantId={plant.id} />
              </Grid.Col>
            </Grid.Col>
          </>
        )}
      </Grid>
    </Container>
    //
  );
};

export default Telemetry;
