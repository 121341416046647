import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

export const subscribeToPublicData = (setData: any) => {
  const unsubscribe = onSnapshot(doc(db, "System", "Public"), (doc) => {
    // const newData =  doc.data()
    // if (newData) {
    //  setData(newData);
    // } else {
    //   console.log("Invalid document structure");
    // }
  });

  return unsubscribe;
};










