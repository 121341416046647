import { createStyles, px, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  image: {
    paddingButton:"md",
    [theme.fn.smallerThan("sm")]: {
      maxWidth:150
    },
  },
}));
