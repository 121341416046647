import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },

  card: {
    border: rem(1),
    position: "relative",
    overflow: "visible",
    padding: theme.spacing.xl,
    height: "100%",
    [theme.fn.smallerThan("xs")]: {
      padding: theme.spacing.md,
    },
  },

  cardValue: {
    fontSize: rem(24),
  },
}));
