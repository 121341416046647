import React from "react";
import { useStyles } from "./styles";
import { Text, Paper, Group } from "@mantine/core";
import {
  IconSolarPanel,
  IconTrendingDown,
  IconTrendingUp,
} from "@tabler/icons-react";
import CountUp from "react-countup";
import { Data } from "../../types/plantsData";

const Load = ({ data }: { data: Data }) => {
  const { classes } = useStyles();

  const solarValue: any = data && data.exportEnergy ? data?.solarConsume:  data?.solarProduce ;

  return (
    <div className={classes.root}>
        <Group position="apart" h={"100%"} w={"100%"} spacing={"lg"}>
          {data?.importEnergy &&
            <Paper className={classes.stat} radius="md" shadow="md" p="xs" h={"100%"} >
              <IconTrendingDown size={56} className={classes.icon} color="#9B2932"/>
              <div>
                <Text className={classes.label}>Import</Text>
                <Text fz="xs" className={classes.count}>
                  <span className={classes.value}>
                    <CountUp
                      decimals={2}
                      end={data.importEnergy}
                      duration={1.5}
                    />
                    {" "}kW
                  </span>
                </Text>
              </div>
            </Paper>
          }

          <Paper className={classes.stat} radius="md" shadow="md" p="xs" h={"100%"} >
            <IconSolarPanel size={56} className={classes.icon} />
            <div>
              <Text className={classes.label}>Solar</Text>
              <Text fz="xs" className={classes.count}>
                <span className={classes.value}>
                  <CountUp decimals={2} end={solarValue} duration={1.5} />
                  {" "}kW
                </span>
              </Text>
            </div>
          </Paper>

          {data?.exportEnergy &&
            <Paper className={classes.stat} radius="md" shadow="md" p="xs" h={"100%"} >
              <IconTrendingUp size={56} className={classes.icon} color="#FFC238" />
              <div>
                <Text className={classes.label}>Export</Text>
                <Text fz="xs" className={classes.count}>
                  <span className={classes.value}>
                    <CountUp
                      decimals={2}
                      end={Math.abs(data.exportEnergy)}
                      duration={1.5}
                    />
                  </span><br/>
                  kW
                </Text>
              </div>
            </Paper>
            }
        </Group>
      </div>
  );
};

export default Load;
