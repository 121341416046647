import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { db, func } from "../firebase";
import { Telemetry, Device } from "../types/plantsData";
import { httpsCallableFromURL } from "firebase/functions";
import dayjs from "dayjs";

export const getPlants = async (): Promise<any> => {
  try {
    const plantCollection = collection(db, "Plants");
    // in this query i want to change this dynamic where client allow to send city and base on tags find where is it adressed
    const q = query(plantCollection, where("province", "==", "Bataan"));
    const querySnapshot = await getDocs(q);
    const plantData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().plantName,
      photo: doc.data().photo,
      city: doc.data().city,
      country: doc.data().country,
      latitude: doc.data().latitude,
      longitude: doc.data().longitude,
      ...doc.data(),
    }));
    return plantData;
  } catch (error) {
    console.error("Error fetching plants:", error);
    return [];
  }
};

export const getMqttServer = async (): Promise<any> => {
  const sytemDoc = doc(db, "System/mqtt");
  const systemSnapshot = await getDoc(sytemDoc);
  if (systemSnapshot.exists()) {
    // console.log(systemSnapshot.data());
    return systemSnapshot.data()
  }
} 

export const getHistory = async (
  plantId: string,
  period: string,
  date: any
) => {
  const callableReturnFunction = httpsCallableFromURL(
    func,
    "https://gethistory-ybjeqa22za-de.a.run.app"
  );
  const response: any = await callableReturnFunction({
    datasetId: plantId,
    period: period,
    date: date
  });  
  if (plantId === "3237770") {
    const values = response.data.power.values;
    const ts = values.map((entry: any) => dayjs(entry.date).format("hh:mm A"));
    const s = values.map((entry: any) => entry.value / 1000);
    const maxSolar = values.reduce((prev: any, current: any) => {
      return prev.value > current.value ? prev : current;
    }, {});
    return { ts, s, maxSolar };
  }

  const data = JSON.parse(response.data);
  const ts = data.map((entry: any) => dayjs(entry.ts).format("hh:mm A"));
  const i = data.map((entry: any) => entry.i);
  const s = data.map((entry: any) => entry.s);
  const e = data.map((entry: any) => entry.e);
  const maxSolar = data.reduce((prev: any, current: any) => {
    return prev.m_s > current.m_s ? prev : current;
  }, {});

  return { ts, i, e, s, maxSolar };
};

export const getData = async (telemetry: Telemetry) => {
  try {
    const devices: Device[] | undefined = telemetry.d;

    if (!devices) {
      throw new Error("Devices array is undefined");
    }

    const emDevices = devices.filter((device: Device) => device.ua === "EM");
    const invDevices = devices.filter((device: Device) => device.ua === "INV");

    const grid = emDevices.reduce((sum: number, device: Device) => {
      const ap = device.r?.ap || 0;
      return sum + (isNaN(ap) ? 0 : ap);
    }, 0);

    const solar = invDevices.reduce((sum: number, device: Device) => {
      const ap = device.r?.ap || 0;
      return sum + (isNaN(ap) ? 0 : ap);
    }, 0);

    const load = ((grid + solar) / 1000).toFixed(2);
    const importEnergy = (Math.abs(grid) / 1000).toFixed(2);
    const exportEnergy = (Math.abs(grid) / 1000).toFixed(2);
    const solarProduce = (solar / 1000).toFixed(2);

    if (grid < 0) {
      return {
        exportEnergy,
        load,
        solarConsume: ((solar - Math.abs(grid)) / 1000).toFixed(2),
      };
    } else {
      return {
        importEnergy,
        solarProduce,
        load,
      };
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getLiveSolarEdge = async (method: string, siteId: string) => {
  const callableReturnFunction = httpsCallableFromURL(
    func,
    "https://getsolaredgeapi-ybjeqa22za-de.a.run.app"
  );
  const response: any = await callableReturnFunction({
    method: method,
    siteId: siteId 
  });

  return response.data.overview;
};
