import React from "react";
import { BrowserRouter, Routes, Route,Navigate } from "react-router-dom";
import Monitoring from "./pages/Monitoring/Monitoring";
import "./App.css"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/bataan" element={<Monitoring/>}></Route>
        <Route path="/" element={<Navigate to={"/bataan"}/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
