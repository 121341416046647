import React, { useEffect, useState } from "react";
import { Grid, Container, Group, Image, useMantineTheme } from "@mantine/core";
import CardHeader from "../CardHeader/CardHeader";
import { subscribeToPublicData } from "../../service/public";
import { publicIcons } from "../../assets/icons";
import { useStyles } from "./styles";
import { companyLogo } from "../../assets/images";

const Header = ({plant, totalKWHGenerated }: any) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const treeFactor = 0.0117 ;
  const CO2Factor = .392;
  
  // convert to kilowatt
  const generatedTotalEnergy = totalKWHGenerated 

  useEffect(() => {
    const unsubscribe = subscribeToPublicData(totalKWHGenerated);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <header>
      <Container size={"95%"} className={classes.container}>
        <Grid
          style={{
            display: "flex",
            alignContent: "center",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: theme.spacing.sm,
          }}
        > 
          <Grid.Col span={12} lg={3} >
            <Grid.Col p={0}>
              <CardHeader
                title={"Total Sites"}
                icon={publicIcons["icon-solar-plant"]}
                value={plant}
              />
            </Grid.Col>
            <Grid.Col p={0} pt={10}>
              <CardHeader
                title={"Carbon Emmision saved"}
                icon={publicIcons["co2"]}
                value={generatedTotalEnergy * CO2Factor}
                size={"kg"}
                decimal
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col lg={3}>
            <Grid.Col p={0}>
              <CardHeader
                title={"Total Generated (refreshes @ 12am)"}
                icon={publicIcons["plants"]}
                value={generatedTotalEnergy}
                size={"kWh"}
                decimal
              />
            </Grid.Col>

            <Grid.Col p={0} pt={10}>
              <CardHeader
                title={"Equivalent Trees Plant"}
                icon={publicIcons["icon-trees-plant"]}
                value={generatedTotalEnergy * treeFactor}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col lg={6} className={classes.bataanLogo}>
            <Group position="center" spacing={"xl"}>
              <Image src={companyLogo["bataan"]} maw={"60%"} />
            </Group>
          </Grid.Col>
        </Grid>
      </Container>
    </header>
  );
};

export default Header;
