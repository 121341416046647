const weatherIcons:{ [key: string]: any }  = {
    "01d": require("./flaticon-weather-01d.svg").default,
    "01n": require("./flaticon-weather-01n.svg").default,
    "02d": require("./flaticon-weather-02d.svg").default,
    "02n": require("./flaticon-weather-02n.svg").default,
    "03d": require("./flaticon-weather-03d.svg").default,
    "03n": require("./flaticon-weather-03n.svg").default,
    "04d": require("./flaticon-weather-04d.svg").default,
    "04n": require("./flaticon-weather-04n.svg").default,
    "09d": require("./flaticon-weather-09d.svg").default,
    "09n": require("./flaticon-weather-09n.svg").default,
    "10d": require("./flaticon-weather-10d.svg").default,
    "10n": require("./flaticon-weather-10n.svg").default,
    "13d": require("./flaticon-weather-13d.svg").default,
    "13n": require("./flaticon-weather-13n.svg").default,
    "50d": require("./flaticon-weather-50d.svg").default,
    "50n": require("./flaticon-weather-50n.svg").default,
    
  };

const publicIcons:{[key: string]: any } = {
  "co2": require("./icon-Co2.svg").default,
  "plants": require("./plants.svg").default,
  "icon-solar-plant": require("./icon-solar-plant.svg").default,
  "icon-trees-plant": require("./icon-trees-plant.svg").default,
}


export {weatherIcons,publicIcons};