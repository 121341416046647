import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  chart: {
    padding: theme.spacing.xl,
    paddingTop:0,
    paddingBottom:0,
    minHeight: 400,

    [theme.fn.smallerThan("xs")]: {
      padding: theme.spacing.xs,
    },
    
  },
  lineChart: {
    width: "100%",
    marginTop: 20,

    [theme.fn.smallerThan("sm")]: {
      marginTop: 0,
    },
  },
}));
