import { Group, Text ,Image} from "@mantine/core";
import React from "react";
import { useStyles } from "./styles";
import { companyLogo } from "../../assets/images";

const Footer = () => {
  const { classes } = useStyles();


  return (
    <footer>
      <Group position='center' pb={"xl"} > 
        <Text c="dimmed" fw={300}>Powered by</Text>
        <Image src={companyLogo["taneko"]} maw={300} className={classes.image}/>
      </Group>
    </footer>
  );
};

export default Footer;