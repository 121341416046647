import React, { useEffect, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
} from "chart.js";

import annotationPlugin from "chartjs-plugin-annotation";
import { Group, Text } from "@mantine/core";
import { useStyles } from "./styles";
import ReactLoading from "react-loading";
import CountUp from "react-countup";
import { Data } from "../../types/plantsData";
import { getHistory } from "../../service/plants";
import dayjs from "dayjs";

ChartJS.register(
  annotationPlugin,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler
);

const Chart = ({ data, plantId }: { data: Data; plantId: string }) => {
  const { classes } = useStyles();
  const [lineChartData, setLineChartData] = useState<any>();
  useEffect(() => {
    const fetchHourlyHistory = async () => {
      try {
        if (!lineChartData) {
          const response = await getHistory(
            plantId,
            "hourly",
            dayjs(new Date()).format("YYYY-MM-DD")
          );
          setLineChartData(response);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchHourlyHistory();
  }, []);


  if (!data) {
    return (
      <Group
        position="center"
        mah={"100%"}
        mt={"15%"}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          fontFamily: "montserrat",
        }}
      >
        <Text fw={400} fz={30}>
          Please wait
        </Text>
        <ReactLoading
          type="bubbles"
          color="#20D478"
          width={100}
          height={"100%"}
        />
      </Group>
    );
  }

  const dataImport = {
    datasets: [
      {
        data: [data.importEnergy || 0, data.solarProduce],
        backgroundColor: ["#9B2932", "#20D478"],
        hoverOffset: 10,
      },
    ],
  };

  const dataExport = {
    datasets: [
      {
        data: [data.exportEnergy || 0, data.solarConsume],
        backgroundColor: ["#EDCC48", "#20D478"],
        hoverOffset: 10,
        weight: 4,
      },
    ],
  };

  const hasExportData = data && data.exportEnergy;
  const chartData = hasExportData ? dataExport : dataImport;

  const lineData = {
    labels: lineChartData?.ts,
    datasets: [
      {
        label: "Import",
        data: lineChartData?.i,
        fill: true,
        backgroundColor: "#9b5157",
        borderColor: "#9b5157",
      },
      {
        label: "Solar",
        data: lineChartData?.s,
        fill: true,
        backgroundColor: "#1aaa60",
        borderColor: "#1aaa60",
      },
      {
        label: "Export",
        data: lineChartData?.e,
        fill: true,
        backgroundColor: "#edd77d",
        borderColor: "#edd77d",
      },
    ],
  };

  const valueToDisplay = hasExportData
    ? Math.fround(data.solarConsume || 0).toFixed(2)
    : Math.fround(data.load || data.solarProduce).toFixed(2);

  function maxValue(ctx: any) {
    let max = 0;
    const dataset = ctx.chart.data.datasets[0];
    dataset.data.forEach(function (el: any) {
      max = Math.max(max, el);
    });
    return max;
  }

  function maxIndex(ctx: any) {
    const max = maxValue(ctx);
    const dataset = ctx.chart.data.datasets[0];
    return dataset.data.indexOf(max);
  }

  let options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      annotation: {
        annotations: {
          label1: {
            type: "label",
            xValue: dayjs(lineChartData?.maxSolar.ts).format("hh:mm A"),
            yValue:
              parseInt(lineChartData?.maxSolar.s) +
              parseInt(lineChartData?.maxSolar.i),
            yAdjust: 5,
            content: "Peak Solar: " + lineChartData?.maxSolar.m_s + " kW",
            font: {
              size: 18,
            },
            position: {
              x: (ctx: any) =>
                maxIndex(ctx) <= 3
                  ? "center"
                  : maxIndex(ctx) >= 10
                  ? "end"
                  : "center",
              y: "start",
            },
          },

          label2: {
            type: "point",
            backgroundColor: "transparent",
            borderColor: "#315f03",
            pointStyle: "rectRounded",
            radius: 10,
            xValue: dayjs(lineChartData?.maxSolar.ts).format("hh:mm A"),
            yValue:
              parseInt(lineChartData?.maxSolar.s) +
              parseInt(lineChartData?.maxSolar.i),
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          font: {
            size: 14,
          },
          callback: function (value: any) {
            return value + " kWh";
          },
        },
        stacked: true,
      },
      x: {
        ticks: {
          font: {
            size: 14,
          },
        },
      },
    },
  };

  if (plantId === "3237770") {    
    options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom" as const,
        },
        annotation: {
          annotations: {
            label1: {
              type: "label",
              xValue: dayjs(lineChartData?.maxSolar.date).format("hh:mm A"),
              yValue:
                parseInt(lineChartData?.maxSolar.value) / 1000,
                // parseInt(lineChartData?.maxSolar.i),
              yAdjust: 25,
              content: "Peak Solar: " + (lineChartData?.maxSolar.value / 1000).toFixed(2) + " kW",
              font: {
                size: 18,
              },
            },
  
            label2: {
              type: "point",
              backgroundColor: "transparent",
              borderColor: "#315f03",
              pointStyle: "rectRounded",
              radius: 10,
              xValue: dayjs(lineChartData?.maxSolar.date).format("hh:mm A"),
              yValue:
                parseInt(lineChartData?.maxSolar.value) / 1000,
                // parseInt(lineChartData?.maxSolar.i),
            },
          },
        },
      },
      scales: {
        y: {
          ticks: {
            font: {
              size: 14,
            },
            callback: function (value: any) {
              return value + " kWh";
            },
          },
          stacked: true,
        },
        x: {
          ticks: {
            font: {
              size: 14,
            },
          },
        },
      },
    };
  
  }

  return (
    <Group position="center" className={classes.chart}>
      {chartData && lineChartData && (
        <>
          <Group position="center">
            <Group
              pos={"absolute"}
              position="center"
              spacing={0}
              style={{ flexDirection: "column" }}
            >
              <Text fz={28} fw={700} c={"#77C583"}>
                <CountUp
                  decimals={2}
                  end={parseFloat(valueToDisplay)}
                  duration={1}
                />
                {"  "}kW
              </Text>
              <Text fz={22} fw={700} opacity={0.6}>
                Load
              </Text>
            </Group>
            <Doughnut
              data={chartData}
              options={{
                responsive: true,
                cutout: "75%",
              }}
            />
          </Group>
          <Group position="center" w={"100%"}>
            <Line
              options={options}
              data={lineData}
              className={classes.lineChart}
              style={{ height: "400px" }}
            />
          </Group>
        </>
      )}
    </Group>
  );
};

export default Chart;
