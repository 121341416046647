import { ThemeIcon, Text, Group, Badge, Paper, Image } from "@mantine/core";
import { IconUserCircle } from "@tabler/icons-react";
import { useStyles, ICON_SIZE } from "./ProfileStyle";
import { plantData } from "../../types/plantsData";

const Profile = ({ plant, time }: { plant: plantData; time: any }) => {
  const { classes } = useStyles();

  //check the last telemetry for online status
  const telemetryTime = new Date(time);
  // console.log(telemetryTime.toString());
  
  return (
    <>
      <Paper className={classes.card}>
        <Group position="center" className={classes.imageGroup}>
          <div style={{ position: "relative" }}>
            <ThemeIcon size={ICON_SIZE} radius={ICON_SIZE} color="#E8F2EC">
              {plant.photo ? (
                <Image
                  src={plant.photo}
                  fit="contain"
                  height={100}
                  width={100}
                  radius="50%"
                />
              ) : (
                <IconUserCircle size="70%" stroke={1.5} color="#c7c6c6" />
              )}
              {telemetryTime.toString() == "Invalid Date" ? null : (
                <div className={classes.onlineIndicator} />
              )}
            </ThemeIcon>
          </div>
        </Group>

        <Text ta="center" fw={700} fz={18} className={classes.title}>
          {plant.name}
        </Text>
        <Group position="center" pt={7}>
          {telemetryTime.toString() == "Invalid Date" ? (
            <Badge color="red" size="sm">
              Connnecting ...
            </Badge>
          ) : (
            <Badge color="green" size="sm">
              Online
            </Badge>
          )}
        </Group>
        <Text ta="center">{plant.phase}</Text>

        <Text c="dimmed" ta="center" fz="sm">
          {telemetryTime instanceof Date &&
            telemetryTime.toString() !== "Invalid Date" &&
            telemetryTime.toDateString() +
              " " +
              telemetryTime.toLocaleTimeString()}
        </Text>
      </Paper>
    </>
  );
};
export default Profile;
