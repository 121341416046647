import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },

  title: {
    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  groupContainer: {
    height: "90%",
    width: "100%",
    flexDirection: "column",
    [theme.fn.smallerThan("md")]: {
      height: "100%",
    },
  },

  icon: {
    marginLeft: "auto",
    marginRight: "auto",
    margin: theme.spacing.xl,
    // color: theme.colors[theme.primaryColor][6],
    color: "#34A853",
  },

  stat: {
    paddingTop: theme.spacing.xl,
    alignItems:"center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    border: `1px solid #dee2e6`,
    borderTop: "6px solid #34a853",
    justifyContent: "space-between",
    backgroundColor: theme.white,
    width: "100%",
    height: "100%",
  },

  label: {
    textTransform: "uppercase",
    fontWeight: 700,
    textAlign:"center",
    fontSize: theme.fontSizes.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.colors.gray[6],
    lineHeight: 1.2,
  },

  value: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 700,
    color: theme.black,
  },

  count: {
    color: theme.colors.gray[6],
  },
}));
