import { createStyles, rem } from "@mantine/core";


export const useStyles = createStyles((theme) => ({
    wrapper: {
      position: 'relative',
      paddingTop: rem(120),
      paddingBottom: rem(80),
  
      [theme.fn.smallerThan('sm')]: {
        paddingTop: rem(0),
        paddingBottom: rem(60),
      },
    },
  }));

