import React from "react";
import { Text, Card, rem, useMantineTheme, Image } from "@mantine/core";
import { useStyles } from "./styles";
import { headerData } from "../../types/headerData";
import CountUp from "react-countup";
const CardHeader = ({ title, icon, value, size, decimal}: headerData) => {
  const { classes } = useStyles();

  return (
    <Card shadow="md" radius="md" className={classes.card} padding="xl">
      <div className={classes.headerContainer}>
        <Image
          src={icon}
          style={{ width: rem(56), marginRight: rem(16) }}
        />
        <div>
          <Text fz="xl" fw={900} className={classes.cardValue}>
            <CountUp end={value} decimals={decimal ? 2 : 0} duration={1.5} /> {size && size}
          </Text>
          <Text fz="sm" fw={400}>
            {title}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default CardHeader;
